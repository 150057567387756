import { IconChevronDown, IconChevronRight, IconEye, IconFileDescription, IconPhoto } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";
//
import {
    AttentionAdaptation,
    AttentionAdxSection,
    AttentionAnalysisPlan,
    AttentionBackground,
    AttentionDI,
    AttentionLowVision,
    AttentionOphthalmology,
    AttentionOptometry,
    AttentionOrigin,
    AttentionOrthoptic,
    AttentionPachymetry,
    AttentionPio,
    AttentionPreconsulting,
    AttentionPreoperative,
    AttentionSurgery
} from "@/models/history";
//
import { TableAccordion } from "@/components/TableAccordion/TableAcordion";
import { listHistorySegments } from "@/constants";
import "../History.scss";
import Details from "./Details";
import DiagnosticAdxDetails from "./Details/DiagnosticAdxDetails";
import HistoryComponent from "./newHistory/HistoryComponent";
import { CommonSelect } from "@/models/sheets/orthoptic";
import { getVersionsList } from "@/services";

interface CardFilter {
    attentionsOptometry: AttentionOptometry[];
    attentionsLowVision: AttentionLowVision[];
    attentionsAdaptation: AttentionAdaptation[];
    attentionsOphthalmology: AttentionOphthalmology[];
    attentionsOrthoptic: AttentionOrthoptic[];
    attentionOrigin: AttentionOrigin[];
    attentionBackground: AttentionBackground[];
    attentionAnalysisPlan: AttentionAnalysisPlan[];
    attentionPachymetry: AttentionPachymetry[];
    attentionDI: AttentionDI[];
    attentionPreoperative: AttentionPreoperative[];
    attentionPreconsulting: AttentionPreconsulting[];
    attentionPio: AttentionPio[];
    attentionSurgery: AttentionSurgery[];
    attentionAdx: AttentionAdxSection[];
    sheet: string;
    patientIdProp?: number;
    patientName?: string;
    patientAge?: number | string;
    patientIdentification?: number | string;
    allData?: boolean;
    sheetLabel?: string;
}

interface DetailParams {
    sheet?: string;
    mcfId?: number;
    diId?: number;
    origin?: string;
    allData?: boolean;
    appId?: number;
}

export const CardFilterByDates = ({
    attentionsOptometry,
    attentionsLowVision,
    attentionsAdaptation,
    attentionsOphthalmology,
    attentionsOrthoptic,
    attentionOrigin,
    attentionBackground,
    attentionAnalysisPlan,
    attentionPachymetry,
    attentionDI,
    attentionPreoperative,
    attentionPreconsulting,
    attentionPio,
    attentionSurgery,
    attentionAdx,
    sheet,
    sheetLabel,
    patientIdProp,
    patientAge,
    patientName,
    patientIdentification,
    allData,
}: CardFilter) => {
    const analysisPlanRef = useRef<HTMLDivElement>(null);

    const [detailModalConfig, setDetailModalConfig] = useState<{ isOpen: boolean; data: DetailParams; }>({
        isOpen: false,
        data: {
            sheet: "",
            mcfId: undefined,
            origin: ""
        }
    });
    const [attentionsOptometryState, setAttentionsOptometryState] = useState<AttentionOptometry[]>([]);
    const [attentionsLowVisionState, setAttentionsLowVisionState] = useState<AttentionLowVision[]>([]);
    const [attentionsAdaptationState, setAttentionsAdaptationState] = useState<AttentionAdaptation[]>([]);
    const [attentionsOphthalmologyState, setAttentionsOphthalmologyState] = useState<AttentionOphthalmology[]>([]);
    const [attentionsOrthopticState, setAttentionsOrthopticState] = useState<AttentionOrthoptic[]>([]);
    const [attentionsOrigin, setAttentionsOrigin] = useState<AttentionOrigin[]>([]);
    const [attentionsBackground, setAttentionsBackground] = useState<AttentionBackground[]>([]);
    const [attentionsAnalysisPlan, setAttentionsAnalysisPlan] = useState<AttentionAnalysisPlan[]>([]);
    const [attentionsPachymetry, setAttentionsPachymetry] = useState<AttentionPachymetry[]>([]);
    const [attentionsDI, setAttentionsDI] = useState<AttentionDI[]>([]);
    const [attentionsPreoperativeState, setAttentionsPreoperativeState] = useState<AttentionPreoperative[]>([]);
    const [attentionsPreconsulting, setAttentionsPreconsulting] = useState<AttentionPreconsulting[]>([]);
    const [attentionsPio, setAttentionsPio] = useState<AttentionPio[]>([]);
    const [attentionsSurgery, setAttentionsSurgery] = useState<AttentionSurgery[]>([]);
    const [attentionAdxState, setAttentionAdxState] = useState<AttentionAdxSection[]>([]);
    const [configModalADX, setConfigModalADX] = useState<{
        isOpenModal: boolean;
        appId?: number | null;
        tab?: string;
        attention?: AttentionAdxSection;
    }>({
        isOpenModal: false,
        appId: null,
        tab: ""
    });
    const [versionsState, setVersionsState] = useState<CommonSelect[]>([]);

    useEffect(() => {
        async function fetchData() {
            const data = await getVersionsList();
            if (data) {
                setVersionsState(data.results);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (attentionsOptometry) {
            setAttentionsOptometryState(attentionsOptometry);
        }
        if (attentionsLowVision) {
            setAttentionsLowVisionState(attentionsLowVision);
        }
        if (attentionsAdaptation) {
            setAttentionsAdaptationState(attentionsAdaptation);
        }
        if (attentionsOphthalmology) {
            setAttentionsOphthalmologyState(attentionsOphthalmology);
        }
        if (attentionsOrthoptic) {
            setAttentionsOrthopticState(attentionsOrthoptic);
        }
        if (attentionOrigin) {
            setAttentionsOrigin(attentionOrigin);
        }
        if (attentionBackground) {
            setAttentionsBackground(attentionBackground);
        }
        if (attentionAnalysisPlan) {
            setAttentionsAnalysisPlan(attentionAnalysisPlan);
        }
        if (attentionPachymetry) {
            setAttentionsPachymetry(attentionPachymetry);
        }
        if (attentionDI) {
            setAttentionsDI(attentionDI);
        }
        if (attentionPreoperative) {
            setAttentionsPreoperativeState(attentionPreoperative);
        }
        if (attentionPreconsulting) {
            setAttentionsPreconsulting(attentionPreconsulting);
        }
        if (attentionPio) {
            setAttentionsPio(attentionPio);
        }
        if (attentionSurgery) {
            setAttentionsSurgery(attentionSurgery);
        }
        if (attentionAdx) {
            setAttentionAdxState(attentionAdx);
        }
    }, [
        attentionAdx,
        attentionsOptometry,
        attentionsLowVision,
        attentionsAdaptation,
        attentionsOphthalmology,
        attentionsOrthoptic,
        attentionBackground,
        attentionOrigin,
        attentionAnalysisPlan,
        attentionDI,
        attentionPachymetry,
        attentionPreoperative,
        attentionPreconsulting,
        attentionPio,
        attentionSurgery
    ]);

    function getLabelByValue(value: string) {
        const segment = listHistorySegments.find(segment => segment.value === value);
        return segment ? segment.label : "";
    }

    const onCloseDetails = () => {
        setDetailModalConfig({
            data: {
                mcfId: undefined,
                sheet: sheet
            },
            isOpen: false
        });
    };

    const onOpenDetails = (segment: DetailParams) => {
        setDetailModalConfig({
            isOpen: true,
            data: segment
        });
    };

    const renderTableOptometry = (attention: AttentionOptometry) => {

        return (
            <div className="overflow-hidden" style={{ borderRadius: "0.7rem" }}>
                <TableAccordion
                    key={`${attention.id}-${attention.mcfId}`}
                    className="tableStyle d-block overflow-auto"
                >
                    <div className="d-flex align-items-center justify-content-between py-3 px-2">
                        <div className="nowrap">
                            {!attention.isOpen && (
                                <IconChevronRight
                                    height={18}
                                    color="#6E6F7C"
                                    className="pointer"
                                    onClick={() => {
                                        const current = attentionsOptometryState.map(item => {
                                            if (item.id === attention.id && item.mcfId === attention.mcfId) {
                                                return { ...item, isOpen: true };
                                            }
                                            return item;
                                        });
                                        setAttentionsOptometryState(current);
                                    }}
                                />
                            )}

                            {attention.isOpen &&
                                <IconChevronDown
                                    height={18}
                                    color="#6E6F7C"
                                    className="pointer"
                                    onClick={() => {
                                        const current = attentionsOptometryState.map(item => {
                                            if (item.id === attention.id && item.mcfId === attention.mcfId) {
                                                return { ...item, isOpen: false };
                                            }
                                            return item;
                                        });
                                        setAttentionsOptometryState(current);
                                    }}
                                />
                            }

                            <span className="ms-2 mt-1 textGray100 fw-light">{attention.date}</span>
                        </div>

                        <div
                            className={"textGray100 fw-light d-flex align-items-center pointer nowrap"}
                            onClick={() => onOpenDetails({ sheet, mcfId: attention.mcfId, appId: attention.appId })}
                        >
                            <IconEye
                                height={15}
                                width={15}
                                className="pointer me-2"
                            />
                            <span>Ver detalles</span>
                        </div>

                    </div>

                    {attention.isOpen && (
                        <thead>
                            <tr>
                                <th className="col-2 ps-3 textTableAccordion fs13" >
                                    Sección
                                </th>
                                <th className="col-5 px-2 textTableAccordion fs13" >
                                    OD
                                </th>

                                <th className="col-5 px-2 textTableAccordion fs13" >
                                    OI
                                </th>

                            </tr>
                        </thead>
                    )}

                    {!attention.isOpen && (
                        <tr className="tableHoverNone">
                            <td className="p-3 nowrap">Atendido por:</td>
                            <td colSpan={2} className="p-3 fs10">{attention.med}</td>
                        </tr>
                    )}

                    {attention.isOpen &&
                        <tbody className="transitionBody">
                            {attention.correction && (
                                <tr>
                                    <td className="nowrap fw-bold text-secondary">AV con corrección</td>
                                    <td>{attention.correction.od}</td>
                                    <td>{attention.correction.oi}</td>
                                </tr>
                            )}
                            {attention.noCorrection && (
                                <tr>
                                    <td className="nowrap fw-bold text-secondary">AV sin corrección</td>
                                    <td>{attention.noCorrection.od}</td>
                                    <td>{attention.noCorrection.oi}</td>
                                </tr>
                            )}
                            {attention.subjective && (
                                <tr>
                                    <td className="nowrap fw-bold text-secondary">R. subjetiva</td>
                                    <td>{attention.subjective.od}</td>
                                    <td>{attention.subjective.oi}</td>
                                </tr>
                            )}
                            {attention.astigmatism && (
                                <tr>
                                    <td className="nowrap fw-bold text-secondary">Astigmatismo</td>
                                    <td>{attention.astigmatism.od}</td>
                                    <td>{attention.astigmatism.oi}</td>
                                </tr>
                            )}
                            <tr className="tableHoverNone">
                                <td className="p-3" >Atendido por:</td>
                                <td colSpan={2} className="p-3 fs10">{attention.med}</td>
                            </tr>
                        </tbody>
                    }
                </TableAccordion>
            </div>
        );
    };

    const renderTableLowVision = (attention: AttentionLowVision) => {

        return (
            <div className="tableStyle overflow-hidden" style={{ borderRadius: "0.7rem" }}>
                <TableAccordion
                    key={`${attention.id}-${attention.mcfId}`}
                    className="d-block overflow-auto"
                >
                    <div className="d-flex align-items-center justify-content-between py-3 px-2">
                        <div className="nowrap">
                            {!attention.isOpen && (
                                <IconChevronRight
                                    height={18}
                                    color="#6E6F7C"
                                    className="pointer"
                                    onClick={() => {
                                        const current = attentionsLowVisionState.map(item => {
                                            if (item.id === attention.id && item.mcfId === attention.mcfId) {
                                                return { ...item, isOpen: true };
                                            }
                                            return item;
                                        });
                                        setAttentionsLowVisionState(current);
                                    }}
                                />
                            )}

                            {attention.isOpen &&
                                <IconChevronDown
                                    height={18}
                                    color="#6E6F7C"
                                    className="pointer"
                                    onClick={() => {
                                        const current = attentionsLowVisionState.map(item => {
                                            if (item.id === attention.id && item.mcfId === attention.mcfId) {
                                                return { ...item, isOpen: false };
                                            }
                                            return item;
                                        });
                                        setAttentionsLowVisionState(current);
                                    }}
                                />
                            }

                            <span className="ms-2 mt-1 textGray100 fw-light">{attention.date}</span>
                        </div>

                        <div
                            className={"px-2 textGray100 fw-light d-flex align-items-center pointer nowrap"}
                            onClick={() => onOpenDetails({ sheet, mcfId: attention.mcfId, appId: attention.appId })}
                        >
                            <IconEye
                                height={15}
                                width={15}
                                className="pointer me-2"
                            />
                            <span>Ver detalles</span>
                        </div>

                    </div>

                    {attention.isOpen && (
                        <thead>
                            <tr>
                                <th className="col-2 ps-3 textTableAccordion fs13" >
                                    Sección
                                </th>
                                <th className="col-5 px-2 textTableAccordion fs13" >
                                    OD
                                </th>

                                <th className="col-5 px-2 textTableAccordion fs13" >
                                    OI
                                </th>

                            </tr>
                        </thead>
                    )}

                    {!attention.isOpen && (
                        <tr className="tableHoverNone">
                            <td className="p-3 nowrap">Atendido por:</td>
                            <td colSpan={2} className="p-3 fs10">{attention.med}</td>
                        </tr>
                    )}

                    {attention.isOpen &&
                        <tbody className="transitionBody">
                            {attention.correction && (
                                <tr>
                                    <td className="nowrap fw-bold text-secondary">AV con corrección</td>
                                    <td>{attention.correction.od}</td>
                                    <td>{attention.correction.oi}</td>
                                </tr>
                            )}
                            {attention.noCorrection && (
                                <tr>
                                    <td className="nowrap fw-bold text-secondary">AV sin corrección</td>
                                    <td>{attention.noCorrection.od}</td>
                                    <td>{attention.noCorrection.oi}</td>
                                </tr>
                            )}
                            {attention.subjective && (
                                <tr>
                                    <td className="nowrap fw-bold text-secondary">R. subjetiva</td>
                                    <td>{attention.subjective.od}</td>
                                    <td>{attention.subjective.oi}</td>
                                </tr>
                            )}
                            {attention.supportsVsnTifloInUse && (
                                <tr>
                                    <td className="nowrap fw-bold text-secondary">Ayudas VSN - TIFLO en uso</td>
                                    <td colSpan={2}>{attention.supportsVsnTifloInUse.supportsVsnTifloInUse}</td>
                                </tr>
                            )}
                            <tr className="tableHoverNone">
                                <td className="p-3" >Atendido por:</td>
                                <td colSpan={2} className="p-3 fs10">{attention.med}</td>
                            </tr>
                        </tbody>
                    }
                </TableAccordion>
            </div>
        );
    };

    const renderTableAdaptation = (attention: AttentionAdaptation) => {

        return (
            <div key={`${attention.id}-${attention.mcfId}`} className="tableStyle overflow-hidden" style={{ borderRadius: "0.7rem" }}>
                <div className="d-flex align-items-center justify-content-between py-3 px-2">
                    <div className="nowrap">
                        {!attention.isOpen && (
                            <IconChevronRight
                                height={18}
                                color="#6E6F7C"
                                className="pointer"
                                onClick={() => {
                                    const current = attentionsAdaptationState.map(item => {
                                        if (item.id === attention.id && item.mcfId === attention.mcfId) {
                                            return { ...item, isOpen: true };
                                        }
                                        return item;
                                    });
                                    setAttentionsAdaptationState(current);
                                }}
                            />
                        )}

                        {attention.isOpen &&
                            <IconChevronDown
                                height={18}
                                color="#6E6F7C"
                                className="pointer"
                                onClick={() => {
                                    const current = attentionsAdaptationState.map(item => {
                                        if (item.id === attention.id && item.mcfId === attention.mcfId) {
                                            return { ...item, isOpen: false };
                                        }
                                        return item;
                                    });
                                    setAttentionsAdaptationState(current);
                                }}
                            />
                        }

                        <span className="ms-2 mt-1 textGray100 fw-light">{attention.date}</span>
                    </div>

                    <div
                        className={"px-2 textGray100 fw-light d-flex align-items-center pointer nowrap"}
                        onClick={() => onOpenDetails({ sheet, mcfId: attention.mcfId, appId: attention.appId })}
                    >
                        <IconEye
                            height={15}
                            width={15}
                            className="pointer me-2"
                        />
                        <span>Ver detalles</span>
                    </div>
                </div>


                <TableAccordion className="overflow-auto">
                    {attention.isOpen &&
                        <tbody className="transitionBody">
                            {attention.contactLenses && (
                                <tr>
                                    <td className="nowrap fw-bold text-secondary">LC de prueba</td>
                                    <td colSpan={2}>{attention.contactLenses}</td>
                                </tr>
                            )}
                            {attention.tolerance && (
                                <tr>
                                    <td className="nowrap fw-bold text-secondary">Tolerancia / Confort</td>
                                    <td colSpan={2}>{attention.tolerance}</td>
                                </tr>
                            )}
                            {attention.centered && (
                                <tr>
                                    <td className="nowrap fw-bold text-secondary">Centrado</td>
                                    <td colSpan={2}>{attention.centered}</td>
                                </tr>
                            )}
                            {attention.motion && (
                                <tr>
                                    <td className="fw-bold text-secondary">Movimiento</td>
                                    <td colSpan={2}>{attention.motion}</td>
                                </tr>
                            )}
                            {attention.glassesToOrder && (
                                <tr>
                                    <td className="fw-bold text-secondary">Lentes a pedir</td>
                                    <td colSpan={2}>
                                        <p className="m-0 mb-2"><span className="fw-bold">OD:</span> {attention.glassesToOrder.od}</p>
                                        <p className="m-0"><span className="fw-bold">OI:</span> {attention.glassesToOrder.oi}</p>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    }
                    <tfoot>
                        <tr className="tableHoverNone">
                            <td className="p-3 nowrap">Atendido por:</td>
                            <td colSpan={2} className="p-3 fs10">{attention.med}</td>
                        </tr>
                    </tfoot>
                </TableAccordion>
            </div>
        );
    };

    const renderTableOphthalmology = (attention: AttentionOphthalmology) => {

        return (
            <div className="tableStyle overflow-hidden" style={{ borderRadius: "0.7rem" }}>
                <TableAccordion
                    key={`${attention.id}-${attention.mcfId}`}
                    className="d-block overflow-auto"
                >
                    <div className="d-flex align-items-center justify-content-between py-3 px-2">
                        <div className="nowrap">
                            {!attention.isOpen && (
                                <IconChevronRight
                                    height={18}
                                    color="#6E6F7C"
                                    className="pointer"
                                    onClick={() => {
                                        const current = attentionsOphthalmologyState.map(item => {
                                            if (item.id === attention.id && item.mcfId === attention.mcfId) {
                                                return { ...item, isOpen: true };
                                            }
                                            return item;
                                        });
                                        setAttentionsOphthalmologyState(current);
                                    }}
                                />
                            )}

                            {attention.isOpen &&
                                <IconChevronDown
                                    height={18}
                                    color="#6E6F7C"
                                    className="pointer"
                                    onClick={() => {
                                        const current = attentionsOphthalmologyState.map(item => {
                                            if (item.id === attention.id && item.mcfId === attention.mcfId) {
                                                return { ...item, isOpen: false };
                                            }
                                            return item;
                                        });
                                        setAttentionsOphthalmologyState(current);
                                    }}
                                />
                            }

                            <span className="ms-2 mt-1 textGray100 fw-light">{attention.date}</span>
                        </div>

                        <div
                            className={"px-2 textGray100 fw-light d-flex align-items-center pointer nowrap"}
                            onClick={() => onOpenDetails({ sheet, mcfId: attention.mcfId, appId: attention.appId })}
                        >
                            <IconEye
                                height={15}
                                width={15}
                                className="pointer me-2"
                            />
                            <span>Ver detalles</span>
                        </div>

                    </div>

                    {attention.isOpen && (
                        <thead>
                            <tr>
                                <th className="col-2 ps-3 textTableAccordion fs13" >
                                    Sección
                                </th>
                                <th className="col-5 px-2 textTableAccordion fs13" >
                                    OD
                                </th>

                                <th className="col-5 px-2 textTableAccordion fs13" >
                                    OI
                                </th>

                            </tr>
                        </thead>
                    )}

                    {!attention.isOpen && (
                        <tr className="tableHoverNone">
                            <td className="p-3 nowrap">Atendido por:</td>
                            <td colSpan={2} className="p-3 fs10">{attention.med}</td>
                        </tr>
                    )}

                    {attention.isOpen &&
                        <tbody className="transitionBody">
                            {attention.external && (
                                <tr>
                                    <td className="nowrap fw-bold text-secondary">Externo</td>
                                    <td>{attention.external.oi}</td>
                                    <td>{attention.external.od}</td>
                                </tr>
                            )}
                            {attention.ppl && (
                                <tr>
                                    <td className="nowrap fw-bold text-secondary">P/P/L</td>
                                    <td>{attention.ppl.od}</td>
                                    <td>{attention.ppl.oi}</td>
                                </tr>
                            )}
                            {attention.screraConjunctiva && (
                                <tr>
                                    <td className="fw-bold text-secondary">Cojuntiva / Esclera</td>
                                    <td>{attention.screraConjunctiva.od}</td>
                                    <td>{attention.screraConjunctiva.oi}</td>
                                </tr>
                            )}
                            {attention.cornea && (
                                <tr>
                                    <td className="fw-bold text-secondary">Córnea</td>
                                    <td>{attention.cornea.od}</td>
                                    <td>{attention.cornea.oi}</td>
                                </tr>
                            )}
                            {attention.previousChamber && (
                                <tr>
                                    <td className="fw-bold text-secondary nowrap">Cámara anterior</td>
                                    <td>{attention.previousChamber.od}</td>
                                    <td>{attention.previousChamber.oi}</td>
                                </tr>
                            )}
                            {attention.iris && (
                                <tr>
                                    <td className="fw-bold text-secondary">Iris</td>
                                    <td>{attention.iris.od}</td>
                                    <td>{attention.iris.oi}</td>
                                </tr>
                            )}
                            {attention.crystalline && (
                                <tr>
                                    <td className="fw-bold text-secondary">Cristalino</td>
                                    <td>{attention.crystalline.od}</td>
                                    <td>{attention.crystalline.oi}</td>
                                </tr>
                            )}
                            {attention.retinaVitreous && (
                                <tr>
                                    <td className="fw-bold text-secondary">Retina/Vítreo</td>
                                    <td>{attention.retinaVitreous.od}</td>
                                    <td>{attention.retinaVitreous.oi}</td>
                                </tr>
                            )}
                            {attention.opticNerve && (
                                <tr>
                                    <td className="fw-bold text-secondary">Nervio óptico</td>
                                    <td>{attention.opticNerve.od}</td>
                                    <td>{attention.opticNerve.oi}</td>
                                </tr>
                            )}
                            <tr className="tableHoverNone nowrap">
                                <td className="p-3" >Atendido por:</td>
                                <td colSpan={2} className="p-3 fs10">{attention.med}</td>
                            </tr>
                        </tbody>
                    }
                </TableAccordion>
            </div>
        );
    };

    const renderTableSurgery = (attention: AttentionSurgery) => {
        return (
            <div className="tableStyle overflow-hidden" style={{ borderRadius: "0.7rem" }}>
                <TableAccordion
                    key={`${attention.appId}`}
                    className="d-block overflow-auto"
                >
                    <div className="d-flex align-items-center justify-content-between py-3 px-2">
                        <div className="nowrap">
                            {!attention.isOpen && (
                                <IconChevronRight
                                    height={18}
                                    color="#6E6F7C"
                                    className="pointer"
                                    onClick={() => {
                                        setAttentionsSurgery(prevState => {
                                            return prevState.map(item => {
                                                if (item.appId === attention.appId) {
                                                    return { ...item, isOpen: !item.isOpen };
                                                }
                                                return item;
                                            });
                                        });
                                    }}
                                />
                            )}

                            {attention.isOpen && (
                                <IconChevronDown
                                    height={18}
                                    color="#6E6F7C"
                                    className="pointer"
                                    onClick={() => {
                                        setAttentionsSurgery(prevState => {
                                            return prevState.map(item => {
                                                if (item.appId === attention.appId) {
                                                    return { ...item, isOpen: !item.isOpen };
                                                }
                                                return item;
                                            });
                                        });
                                    }}
                                />
                            )}

                            <span className="ms-2 mt-1 textGray100 fw-light">{attention.date}</span>
                        </div>

                        <div
                            className={"px-2 textGray100 fw-light d-flex align-items-center pointer nowrap"}
                            onClick={() => onOpenDetails({ sheet, appId: attention.appId })}
                        >
                            <IconEye
                                height={15}
                                width={15}
                                className="pointer me-2"
                            />
                            <span>Ver detalles</span>
                        </div>

                    </div>
                    <tbody className="transitionBody">
                        {attention.isOpen && (
                            <>
                                <tr>
                                    <td className="wrap fw-bolder text-secondary">Cirugía</td>
                                    <td>{attention.serviceMain || "--"}</td>
                                </tr>
                                <tr>
                                    <td className="wrap fw-bolder text-secondary">Diagnóstico principal</td>
                                    <td>{attention.mainDiagnostic || "--"}</td>
                                </tr>
                                <tr>
                                    <td className="wrap fw-bolder text-secondary">Cirujano</td>
                                    <td>{attention.surgeonName || "--"}</td>
                                </tr>
                                <tr>
                                    <td className="wrap fw-bolder text-secondary">Anestesiólogo</td>
                                    <td>{attention.anesName || "--"}</td>
                                </tr>
                            </>
                        )}
                    </tbody>
                </TableAccordion>
            </div>
        );
    };

    const renderTablePreconsult = (attention: AttentionPreconsulting) => {
        return (
            <div
                key={`${attention.id}-${attention.mcfId}`}
                className="tableStyle overflow-hidden"
                style={{ borderRadius: "0.7rem" }}
            >
                <div className="d-flex align-items-center justify-content-between py-3 px-2">
                    <div className="nowrap">
                        {!attention.isOpen && (
                            <IconChevronRight
                                height={18}
                                color="#6E6F7C"
                                className="pointer"
                                onClick={() => {
                                    const current = attentionsPreconsulting.map(item => {
                                        if (item.id === attention.id && item.mcfId === attention.mcfId) {
                                            return { ...item, isOpen: true };
                                        }
                                        return item;
                                    });
                                    setAttentionsPreconsulting(current);
                                }}
                            />
                        )}

                        {attention.isOpen &&
                            <IconChevronDown
                                height={18}
                                color="#6E6F7C"
                                className="pointer"
                                onClick={() => {
                                    const current = attentionsPreconsulting.map(item => {
                                        if (item.id === attention.id && item.mcfId === attention.mcfId) {
                                            return { ...item, isOpen: false };
                                        }
                                        return item;
                                    });
                                    setAttentionsPreconsulting(current);
                                }}
                            />
                        }

                        <span className="ms-2 mt-1 textGray100 fw-light">{attention.date}</span>
                    </div>

                    <div
                        className={"px-2 textGray100 fw-light d-flex align-items-center pointer nowrap"}
                        onClick={() => onOpenDetails({ sheet, mcfId: attention.mcfId, appId: attention.appId, origin: attention.origin })}
                    >
                        <IconEye
                            height={15}
                            width={15}
                            className="pointer me-2"
                        />
                        <span>Ver detalles</span>
                    </div>
                </div>

                <TableAccordion className="overflow-auto">
                    {attention.isOpen &&
                        <tbody className="transitionBody">
                            {attention.dilated !== null ? (
                                <tr>
                                    <td className="nowrap fw-bold text-secondary">Dilatado</td>
                                    <td>{attention.dilated}</td>
                                </tr>
                            ) : ""}
                            {attention.bloodPressureDiastolic && attention.bloodPressureDiastolic ? (
                                <tr>
                                    <td className="nowrap fw-bold text-secondary">Tensión arterial</td>
                                    <td>{attention.bloodPressureDiastolic} / {attention.bloodPressureDiastolic}</td>
                                </tr>
                            ) : ""}
                            {attention.heartRate ? (
                                <tr>
                                    <td className="nowrap fw-bold text-secondary">F. Cardiaca</td>
                                    <td>{attention.heartRate}</td>
                                </tr>
                            ) : ""}
                            {attention.breathingFrequency ? (
                                <tr>
                                    <td className="nowrap fw-bold text-secondary">F. Respiratoria</td>
                                    <td>{attention.breathingFrequency}</td>
                                </tr>
                            ) : ""}
                            {attention.bodyWeight ? (
                                <tr>
                                    <td className="nowrap fw-bold text-secondary">Peso corporal</td>
                                    <td>{attention.bodyWeight}</td>
                                </tr>
                            ) : ""}
                        </tbody>
                    }
                    <tfoot>
                        <tr className="tableHoverNone">
                            <td className="p-3 nowrap">Atendido por:</td>
                            <td className="p-3 fs10">{attention.med}</td>
                        </tr>
                    </tfoot>
                </TableAccordion>
            </div>
        );
    };

    const renderTableAntecedents = (attention: AttentionBackground) => {
        return (
            <div key={`${attention.id}-${attention.mcfId}`} className="tableStyle overflow-hidden" style={{ borderRadius: "0.7rem" }}>
                <div className="d-flex align-items-center justify-content-between py-3 px-2">
                    <div className="nowrap">
                        <span className="ms-2 mt-1 textGray100 fw-light">{attention.createdAt}</span>
                    </div>

                    <div
                        className={"px-2 textGray100 fw-light d-flex align-items-center pointer nowrap"}
                        onClick={() => onOpenDetails({ sheet, mcfId: attention.mcfId, origin: attention.origin, appId: attention.appId })}
                    >
                        <IconEye
                            height={15}
                            width={15}
                            className="pointer me-2"
                        />
                        <span>Ver detalles</span>
                    </div>
                </div>
                <TableAccordion>
                    <tbody className="transitionBody w-100">
                        <tr className="tableHoverNone">
                            <td className="p-3 nowrap">Atendido por:</td>
                            <td className="w-100 p-3 fs10">{attention.medName}</td>
                        </tr>
                    </tbody>
                </TableAccordion>
            </div>
        );
    };

    const renderTableOrthoptic = (attention: AttentionOrthoptic) => {

        return (
            <div key={`${attention.id}-${attention.mcfId}`} className="tableStyle overflow-hidden" style={{ borderRadius: "0.7rem" }}>
                <div className="d-flex align-items-center justify-content-between py-3 px-2 w-100">
                    <div className="nowrap">
                        {!attention.isOpen && (
                            <IconChevronRight
                                height={18}
                                color="#6E6F7C"
                                className="pointer"
                                onClick={() => {
                                    const current = attentionsOrthopticState.map(item => {
                                        if (item.id === attention.id && item.mcfId === attention.mcfId) {
                                            return { ...item, isOpen: true };
                                        }
                                        return item;
                                    });
                                    setAttentionsOrthopticState(current);
                                }}
                            />
                        )}

                        {attention.isOpen &&
                            <IconChevronDown
                                height={18}
                                color="#6E6F7C"
                                className="pointer"
                                onClick={() => {
                                    const current = attentionsOrthopticState.map(item => {
                                        if (item.id === attention.id && item.mcfId === attention.mcfId) {
                                            return { ...item, isOpen: false };
                                        }
                                        return item;
                                    });
                                    setAttentionsOrthopticState(current);
                                }}
                            />
                        }

                        <span className="ms-2 mt-1 textGray100 fw-light">{attention.date}</span>
                    </div>

                    <div
                        className={"px-2 textGray100 fw-light d-flex align-items-center pointer nowrap"}
                        onClick={() => onOpenDetails({ sheet, mcfId: attention.mcfId, appId: attention.appId })}
                    >
                        <IconEye
                            height={15}
                            width={15}
                            className="pointer me-2"
                        />
                        <span>Ver detalles</span>
                    </div>
                </div>

                <div className="overflow-auto">
                    <TableAccordion className="overflow-auto">
                        {attention.isOpen && (
                            <thead>
                                <tr>
                                    <th className="ps-3 textTableAccordion fs13" >
                                        Sección
                                    </th>
                                    <th className=" textTableAccordion fs13" >
                                        OD
                                    </th>
                                    <th className=" textTableAccordion fs13">
                                        OI
                                    </th>
                                </tr>
                            </thead>
                        )}

                        {attention.isOpen &&
                            <tbody className="transitionBody">
                                {attention.breadthAccommodation && (
                                    <tr>
                                        <td className="nowrap fw-bold text-secondary">Amplitud de <br />acomodación</td>
                                        <td >{attention.breadthAccommodation.od}</td>
                                        <td >{attention.breadthAccommodation.oi}</td>
                                    </tr>
                                )}
                                {attention.correction && (
                                    <tr>
                                        <td className="nowrap fw-bold text-secondary">AV con corrección</td>
                                        <td style={{ minWidth: "120px" }}>{attention.correction.od}</td>
                                        <td style={{ minWidth: "120px" }}>{attention.correction.oi}</td>
                                    </tr>
                                )}
                                {attention.noCorrection && (
                                    <tr>
                                        <td className="nowrap fw-bold text-secondary">AV sin corrección</td>
                                        <td>{attention.noCorrection.od}</td>
                                        <td>{attention.noCorrection.oi}</td>
                                    </tr>
                                )}
                                {attention.functionalOptometryFinalFormula && (
                                    <tr>
                                        <td className="nowrap fw-bold text-secondary">F.F.F Optometría</td>
                                        <td>{attention.functionalOptometryFinalFormula.od}</td>
                                        <td>{attention.functionalOptometryFinalFormula.oi}</td>
                                    </tr>
                                )}
                                {attention.accommodationFlexibility && (
                                    <tr>
                                        <td className="fw-bold text-secondary">Flexibilidad de acomodación</td>
                                        <td>{attention.accommodationFlexibility.od}</td>
                                        <td>{attention.accommodationFlexibility.oi}</td>
                                    </tr>
                                )}
                            </tbody>
                        }
                        <tfoot>
                            <tr className="tableHoverNone">
                                <td className="p-3 nowrap">Atendido por:</td>
                                <td className="p-3 fs10 w-100 nowrap">{attention.med}</td>
                            </tr>
                        </tfoot>
                    </TableAccordion>
                </div>
            </div>
        );
    };

    const renderTableAttentionOrigin = (attention: AttentionOrigin) => {

        return (
            <div key={`${attention.id}-${attention.mcfId}`} className="tableStyle overflow-hidden" style={{ borderRadius: "0.7rem" }}>
                <div className="d-flex align-items-center justify-content-between py-3 px-2">
                    <div className="nowrap">
                        {!attention.isOpen && (
                            <IconChevronRight
                                height={18}
                                color="#6E6F7C"
                                className="pointer"
                                onClick={() => {
                                    const current = attentionsOrigin.map(item => {
                                        if (item.id === attention.id && item.mcfId === attention.mcfId) {
                                            return { ...item, isOpen: true };
                                        }
                                        return item;
                                    });
                                    setAttentionsOrigin(current);
                                }}
                            />
                        )}

                        {attention.isOpen &&
                            <IconChevronDown
                                height={18}
                                color="#6E6F7C"
                                className="pointer"
                                onClick={() => {
                                    const current = attentionsOrigin.map(item => {
                                        if (item.id === attention.id && item.mcfId === attention.mcfId) {
                                            return { ...item, isOpen: false };
                                        }
                                        return item;
                                    });
                                    setAttentionsOrigin(current);
                                }}
                            />
                        }

                        <span className="ms-2 mt-1 textGray100 fw-light">{attention.createdAt}</span>
                    </div>

                    <div
                        className={"px-2 textGray100 fw-light d-flex align-items-center pointer nowrap"}
                        onClick={() => {
                            if (attention.origin === "diagnosticAids") {
                                setConfigModalADX({ ...configModalADX, isOpenModal: true, appId: attention.appId, tab: "exam" });
                            } else {
                                onOpenDetails({ sheet: attention.origin, mcfId: attention.mcfId, appId: attention.appId });
                            }
                        }}
                    >
                        <IconEye
                            height={15}
                            width={15}
                            className="pointer me-2"
                        />
                        <span>Ver detalles</span>
                    </div>

                </div>

                <TableAccordion>
                    <tbody className="transitionBody">
                        {attention.isOpen && (
                            <>
                                {
                                    attention.origin ? (
                                        <tr>
                                            <td className="nowrap fw-bold text-secondary">Origen</td>
                                            <td>{listHistorySegments.find(item => item.value === attention.origin)?.label}</td>
                                        </tr>
                                    ) : ""
                                }
                                {attention.attendingReason ? (
                                    <tr>
                                        <td className="nowrap fw-bold text-secondary">Motivo de consulta</td>
                                        <td className="overflow-hidden text-ellipsis" style={{ maxWidth: "10vw" }}>
                                            {attention.attendingReason}
                                        </td>
                                    </tr>
                                ) : ""}
                                {attention.currentIllness ? (
                                    <tr>
                                        <td className="nowrap fw-bold text-secondary">Enfermedad actual</td>
                                        <td className="overflow-hidden text-ellipsis" style={{ maxWidth: "10vw" }}>
                                            {attention.currentIllness}
                                        </td>
                                    </tr>
                                ) : ""}
                                {attention.abserveEvent !== null ? (
                                    <tr>
                                        <td className="nowrap fw-bold text-secondary">Evento adverso</td>
                                        <td>{attention.abserveEvent ? "Sí" : "No"}</td>
                                    </tr>
                                ) : ""}

                            </>
                        )}
                    </tbody>
                    <tfoot>
                        <tr className="tableHoverNone">
                            <td className="py-3 nowrap">Atendido por:</td>
                            <td className="py-3 fs10">{attention.med}</td>
                        </tr>
                    </tfoot>
                </TableAccordion>
            </div>
        );
    };

    const renderTableAnalysisPlan = (attention: AttentionAnalysisPlan) => {

        return (
            <div
                key={`${attention.id}-${attention.mcfId}`}
                ref={analysisPlanRef}
                className="tableStyle overflow-hidden position-relative"
                style={{ borderRadius: "0.7rem" }}
            >
                <div className="d-flex align-items-center justify-content-between py-3 px-2">
                    <div className="nowrap">
                        {!attention.isOpen && (
                            <IconChevronRight
                                height={18}
                                color="#6E6F7C"
                                className="pointer"
                                onClick={() => {
                                    const current = attentionsAnalysisPlan.map(item => {
                                        if (item.id === attention.id && item.mcfId === attention.mcfId) {
                                            return { ...item, isOpen: true };
                                        }
                                        return item;
                                    });
                                    setAttentionsAnalysisPlan(current);
                                }}
                            />
                        )}

                        {attention.isOpen &&
                            <IconChevronDown
                                height={18}
                                color="#6E6F7C"
                                className="pointer"
                                onClick={() => {
                                    const current = attentionsAnalysisPlan.map(item => {
                                        if (item.id === attention.id && item.mcfId === attention.mcfId) {
                                            return { ...item, isOpen: false };
                                        }
                                        return item;
                                    });
                                    setAttentionsAnalysisPlan(current);
                                }}
                            />
                        }

                        <span className="ms-2 mt-1 textGray100 fw-light">{attention.createdAt}</span>
                    </div>


                    <div
                        className={"px-2 textGray100 fw-light d-flex align-items-center pointer nowrap"}
                        onClick={() => {
                            if (attention.origin === "diagnosticAids") {
                                setConfigModalADX({ ...configModalADX, isOpenModal: true, appId: attention.appId, tab: "exam" });
                            } else {
                                onOpenDetails({ sheet: attention.origin, mcfId: attention.mcfId, appId: attention.appId });
                            }
                        }

                        }
                    >
                        <IconEye
                            height={15}
                            width={15}
                            className="pointer me-2"
                        />
                        <span>Ver detalles</span>
                    </div>

                </div>
                <div className="px-3">
                    <p className="text-secondary">Origen: {listHistorySegments.find(item => item.value === attention.origin)?.label}</p>
                    {attention.isOpen ? (
                        <p >
                            {attention.analysisPlan}
                        </p>
                    ) : ""}
                </div>
            </div>
        );
    };

    const renderTablePachymetry = (attention: AttentionPachymetry) => {

        return (
            <div
                key={`${attention.id}-${attention.mcfId}`}
                ref={analysisPlanRef}
                className="tableStyle overflow-hidden position-relative"
                style={{ borderRadius: "0.7rem" }}
            >
                <div className="d-flex align-items-center justify-content-between py-3 px-2">
                    <div className="nowrap">
                        <span className="ms-2 mt-1 textGray100 fw-light">{attention.createdAt}</span>
                    </div>

                    <div
                        className={"px-2 textGray100 fw-light d-flex align-items-center pointer nowrap"}
                        onClick={() => {
                            if (attention.origin === "diagnosticAids") {
                                setConfigModalADX({ ...configModalADX, isOpenModal: true, appId: attention.appId, tab: "exam" });
                            } else {
                                onOpenDetails({ sheet: attention.origin, mcfId: attention.mcfId, appId: attention.appId });
                            }
                        }
                        }
                    >
                        <IconEye
                            height={15}
                            width={15}
                            className="pointer me-2"
                        />
                        <span>Ver detalles</span>
                    </div>

                </div>
                <TableAccordion>
                    <thead>
                        <tr className="tableHoverNone">
                            <td className="nowrap text-secondary" colSpan={3}>
                                <span className="fw-bold">Origen: </span>
                                {listHistorySegments.find(item => item.value === attention.origin)?.label}
                            </td>
                        </tr>
                        <tr>
                            <th className="text-secondary">Sección</th>
                            <th className="text-secondary">OD</th>
                            <th className="text-secondary">OI</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-secondary fw-bold">Grosor corneal</td>
                            <td>{attention.od?.pachymetry ?? "--"}</td>
                            <td>{attention.oi?.pachymetry ?? "--"}</td>
                        </tr>
                        <tr>
                            <td className="text-secondary fw-bold">Corrección</td>
                            <td>{attention.od?.correction ?? "--"}</td>
                            <td>{attention.oi?.correction ?? "--"}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr className="tableHoverNone">
                            <td className="nowrap">Atendido por:</td>
                            <td className="fs10" colSpan={2}>{attention.med}</td>
                        </tr>
                    </tfoot>
                </TableAccordion>
            </div>
        );
    };


    const renderTablePreoperative = (attention: AttentionPreoperative) => {
        return (
            <div key={attention.id} className="tableStyle overflow-hidden" style={{ borderRadius: "0.7rem" }}>
                <div className="d-flex align-items-center justify-content-between py-3 px-2">
                    <div className="nowrap">
                        <span className="textGray100 fw-light">{attention.date}</span>
                    </div>


                    <div
                        className={"pe-2 textGray100 fw-light d-flex align-items-center pointer nowrap"}
                        onClick={() => onOpenDetails({ sheet, mcfId: attention.mcfId, appId: attention.appId })}
                    >
                        <IconEye
                            height={15}
                            width={15}
                            className="pointer me-2"
                        />
                        <span>Ver detalles</span>
                    </div>
                </div>

                <TableAccordion className="overflow-auto">
                    <tbody>
                        <tr>
                            <td colSpan={3} className="text-secondary">
                                Aprobación de cirugía
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>{attention.suitable}</td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <span>Riesgo anestésico: {attention.anestheticRisk}</span>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td className="p-3 nowrap">Atendido por:</td>
                            <td colSpan={2} className="p-3 fs10">{attention.med}</td>
                        </tr>
                    </tfoot>
                </TableAccordion>
            </div>
        );
    };

    const renderTableDI = (attention: AttentionDI[]) => {

        return (
            <div
                className="tableStyle overflow-hidden position-relative"
                style={{ borderRadius: "0.7rem" }}
            >
                <TableAccordion>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th className="w-100 ps-5">Impresión diagnóstica</th>
                            <th>Ojo</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                </TableAccordion>
                {attention.map(item => (
                    <>
                        <TableAccordion>
                            <tbody className="transitionBody w-100">
                                {item.diagnosticImpression?.map(el => (
                                    <tr>
                                        <td className="nowrap">
                                            {item.date}
                                        </td>
                                        <td>
                                            {el.descriptionAmp &&
                                                <>
                                                    <span>
                                                        <b>AMP / </b>{el.descriptionAmp}
                                                    </span> <br />
                                                </>
                                            }
                                            <span><b>{el.cie10} / </b>{el.descriptionCie10}</span>
                                        </td>
                                        <td>
                                            {el.eye}
                                        </td>
                                        <td>
                                            <IconEye
                                                className="hoverable pointer"
                                                onClick={() => onOpenDetails({ sheet, mcfId: el.mcfId, diId: el.id, appId: el.appId })}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </TableAccordion>
                    </>
                ))}
            </div>
        );
    };

    const renderTablePio = () => {

        return (
            <div className="tableStyle overflow-hidden" style={{ borderRadius: "0.7rem" }}>
                <TableAccordion>
                    <thead>
                        <tr>
                            <th className="text-secondary">Fecha</th>
                            <th className="text-secondary">OD</th>
                            <th className="text-secondary">OI</th>
                            <th className="text-secondary">Origen</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className="transitionBody w-100">
                        {attentionPio.map(item => (
                            <tr key={item.id + "-" + item.mcfId}>
                                <td>{item.createdAt}</td>
                                <td>{item.od}</td>
                                <td>{item.oi}</td>
                                <td>
                                    <div>{listHistorySegments.find(el => el.value === item.origin)?.label}</div>
                                    <div>{item.originSegment}</div>
                                </td>
                                <td>
                                    <IconEye
                                        className="hoverable pointer"
                                        onClick={() => onOpenDetails({ sheet: item.origin, mcfId: item.mcfId, appId: item.appId })}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </TableAccordion>
            </div>
        );
    };
    const renderTableADX = (attention: AttentionAdxSection) => {

        return (
            <div className="overflow-hidden" style={{ borderRadius: "0.7rem" }}>
                <TableAccordion
                    key={`${attention.adxId}`}
                    className="tableStyle d-block overflow-auto"
                >
                    <div className="d-flex align-items-center justify-content-between py-3 px-2">
                        <div className="nowrap">
                            {!attention.isOpen && (
                                <IconChevronRight
                                    height={18}
                                    color="#6E6F7C"
                                    className="pointer"
                                    onClick={() => {
                                        const current = attentionAdxState.map(item => {
                                            if (item.adxId === attention.adxId && item.mcfId === attention.mcfId) {
                                                return { ...item, isOpen: true };
                                            }
                                            return item;
                                        });
                                        setAttentionAdxState(current);
                                    }}
                                />
                            )}

                            {attention.isOpen &&
                                <IconChevronDown
                                    height={18}
                                    color="#6E6F7C"
                                    className="pointer"
                                    onClick={() => {
                                        const current = attentionAdxState.map(item => {
                                            if (item.adxId === attention.adxId && item.mcfId === attention.mcfId) {
                                                return { ...item, isOpen: false };
                                            }
                                            return item;
                                        });
                                        setAttentionAdxState(current);
                                    }}
                                />
                            }

                            <span className="ms-2 mt-1 textGray100 fw-light">{attention.date}</span>
                        </div>

                        <div
                            className={"textGray100 fw-light d-flex align-items-center pointer nowrap"}
                        >
                            <div
                                className="pointer mx-2 align-self-center"
                                data-tooltip-id="allergies-tooltip"
                                data-tooltip-content={"Ver detalle"}
                            >
                                <IconEye
                                    height={15}
                                    width={15}
                                    className="pointer me-2"
                                    onClick={() => {
                                        setConfigModalADX({ ...configModalADX, isOpenModal: true, appId: attention.appId, tab: "exam" });
                                    }}
                                />
                            </div>
                            {Boolean(attention.requiresImage) &&

                                <div
                                    className="pointer mx-2 align-self-center"
                                    data-tooltip-id="allergies-tooltip"
                                    data-tooltip-content={"Ver imágenes"}
                                    onClick={() => {
                                        setConfigModalADX({ ...configModalADX, isOpenModal: true, appId: attention.appId, tab: "images" });
                                    }}
                                >
                                    <IconPhoto
                                        height={15}
                                        width={15}
                                        className="pointer me-2"
                                    />
                                </div>
                            }
                            {(
                                ((attention.requiresImage) === 0 && (attention.reportRequired)) === 1 ||
                                ((attention.requiresImage) === 0 && (attention.reportRequired)) === 0 ||
                                ((attention.requiresImage) === 1 && (attention.reportRequired) === 1)
                            )
                                &&
                                <div
                                    className="pointer mx-2 align-self-center"
                                    data-tooltip-id="allergies-tooltip"
                                    data-tooltip-content={"Ver informes"}
                                    onClick={() => {
                                        setConfigModalADX({ ...configModalADX, isOpenModal: true, appId: attention.appId, tab: "report", attention: attention });
                                    }}
                                >
                                    <IconFileDescription
                                        height={15}
                                        width={15}
                                        className="pointer me-2"
                                    />
                                </div>
                            }
                        </div>

                    </div>



                    {
                        !attention.isOpen && (
                            <tr className="tableHoverNone">
                                <td className="p-3 nowrap">Atendido por:</td>
                                <td colSpan={2} className="p-3 fs10">{attention.attendedBy ?? "--"}</td>
                            </tr>
                        )
                    }

                    {
                        attention.isOpen &&
                        <tbody className="transitionBody">
                            <tr>
                                <td className="nowrap fw-bold text-secondary">Examen</td>
                                <td className="nowrap fw-bold text-secondary">{attention.srvName}</td>
                            </tr>
                            <tr>
                                <td className="nowrap fw-bold text-secondary">Solicitado por:</td>
                                <td>{attention.RequestedBy ?? "--"}</td>
                            </tr>
                            <tr>
                                <td className="nowrap fw-bold text-secondary">Leído por:</td>
                                <td>{attention.readingBy ?? "--"}</td>
                            </tr>

                            <tr className="tableHoverNone">
                                <td className="p-3" >Atendido por:</td>
                                <td colSpan={2} className="p-3 fs10">{attention.attendedBy ?? "--"}</td>
                            </tr>
                        </tbody>
                    }
                </TableAccordion >
            </div >
        );
    };



    const render = () => {
        return (
            <>
                <DiagnosticAdxDetails configModal={configModalADX} setConfigModal={setConfigModalADX} showReport={
                    (
                        ((configModalADX?.attention?.requiresImage) === 0 && (configModalADX?.attention?.reportRequired)) === 1 ||
                        ((configModalADX?.attention?.requiresImage) === 0 && (configModalADX?.attention?.reportRequired)) === 0 ||
                        ((configModalADX?.attention?.requiresImage) === 1 && (configModalADX?.attention?.reportRequired) === 1)
                    )
                } />

                <section className="mt-4">
                    {sheet === "optometry" && (attentionsOptometryState.length > 0
                        ? attentionsOptometryState.map((card) => renderTableOptometry(card))
                        : <h5 className="text-muted text-center mt-5">No existen atenciones previas.</h5>)
                    }

                    {sheet === "lowVision" && (attentionsLowVisionState.length > 0
                        ? attentionsLowVisionState.map((card) => renderTableLowVision(card))
                        : <h5 className="text-muted text-center mt-5">No existen atenciones previas.</h5>)
                    }

                    {sheet === "adaptation" && (attentionsAdaptationState.length > 0
                        ? attentionsAdaptationState.map((card) => renderTableAdaptation(card))
                        : <h5 className="text-muted text-center mt-5">No existen atenciones previas.</h5>)
                    }
                    {sheet === "ophthalmology" && (attentionsOphthalmologyState.length > 0
                        ? attentionsOphthalmologyState.map((card) => renderTableOphthalmology(card))
                        : <h5 className="text-muted text-center mt-5">No existen atenciones previas.</h5>)
                    }
                    {sheet === "preconsulting" && (attentionsPreconsulting.length > 0
                        ? attentionsPreconsulting.map(card => renderTablePreconsult(card))
                        : <h5 className="text-muted text-center mt-5">No existen atenciones previas.</h5>)
                    }
                    {sheet === "background" && (attentionBackground.length > 0
                        ? attentionsBackground.map(card => renderTableAntecedents(card))
                        : <h5 className="text-muted text-center mt-5">No existen atenciones previas.</h5>)
                    }
                    {sheet === "orthoptic" && (attentionsOrthopticState.length > 0
                        ? attentionsOrthopticState.map(card => renderTableOrthoptic(card))
                        : <h5 className="text-muted text-center mt-5">No existen atenciones previas.</h5>)
                    }
                    {sheet === "attentionOrigin" && (attentionsOrigin.length > 0
                        ? attentionsOrigin.map(card => renderTableAttentionOrigin(card))
                        : <h5 className="text-muted text-center mt-5">No existen atenciones previas.</h5>)
                    }
                    {sheet === "analysisPlan" && (attentionsAnalysisPlan.length > 0
                        ? attentionsAnalysisPlan.map(card => renderTableAnalysisPlan(card))
                        : <h5 className="text-muted text-center mt-5">No existen atenciones previas.</h5>)
                    }
                    {sheet === "pachymetry" && (attentionsPachymetry.length > 0
                        ? attentionsPachymetry.map(card => renderTablePachymetry(card))
                        : <h5 className="text-muted text-center mt-5">No existen atenciones previas.</h5>)
                    }
                    {sheet === "diagnosticImpression" && (attentionsDI.length > 0
                        ? renderTableDI(attentionsDI)
                        : <h5 className="text-muted text-center mt-5">No existen atenciones previas.</h5>)
                    }
                    {sheet === "preoperative" && (attentionsPreoperativeState.length > 0
                        ? attentionsPreoperativeState.map(card => renderTablePreoperative(card))
                        : <h5 className="text-muted text-center mt-5">No existen atenciones previas.</h5>)
                    }
                    {sheet === "pio" && (attentionsPio.length > 0
                        ? renderTablePio()
                        : <h5 className="text-muted text-center mt-5">No existen atenciones previas.</h5>)
                    }
                    {sheet === "surgery" && (attentionsSurgery.length > 0
                        ? attentionsSurgery.map((card) => renderTableSurgery(card))
                        : <h5 className="text-muted text-center mt-5">No existen atenciones previas.</h5>)
                    }
                    {sheet === "diagnosticAids" && (attentionAdxState.length > 0
                        ? attentionAdxState.map(card => renderTableADX(card))
                        : <h5 className="text-muted text-center mt-5">No existen atenciones previas.</h5>)
                    }
                </section>

                {((detailModalConfig.data.sheet !== "diagnosticAids") && (detailModalConfig.isOpen && detailModalConfig.data.sheet && sheetLabel && detailModalConfig?.data?.appId)) && (
                    <HistoryComponent
                        appId={detailModalConfig?.data?.appId}
                        onClose={onCloseDetails}
                        additionalData={detailModalConfig?.data}
                        dataVersionList={versionsState}
                        isOpen={detailModalConfig.isOpen}
                        sheet={{
                            name: detailModalConfig.data.sheet ? getLabelByValue(detailModalConfig.data.sheet) : sheetLabel,
                            prefix: detailModalConfig.data.sheet ? detailModalConfig.data.sheet : sheet,
                            origin: detailModalConfig.data.origin ? detailModalConfig.data.origin : ""
                        }}


                    />
                )}
                {detailModalConfig.data.sheet === "diagnosticImpression" &&
                    <Details
                        allData={allData}
                        data={{
                            sheet: detailModalConfig.data.sheet || "",
                            mcfId: Number(detailModalConfig.data.mcfId),
                            diId: detailModalConfig.data.diId,
                            origin: detailModalConfig.data.origin,
                            appId: detailModalConfig.data.appId
                        }}
                        patientName={patientName}
                        patientAge={patientAge}
                        isOpen={detailModalConfig.isOpen}
                        patientIdProp={patientIdProp}
                        onClose={onCloseDetails}
                        onChangeDetail={(mcfId) => setDetailModalConfig({
                            ...detailModalConfig,
                            data: {
                                ...detailModalConfig.data,
                                mcfId: mcfId
                            }
                        })}
                        patientIdentification={patientIdentification}
                    />
                }

            </>
        );
    };

    return render();
};
import { RadioButton, SectionCard } from "@/components";
import SightTest from "../SightTest/SightTest";
import { OrthopticPayload, SightConfig } from "@/models/sheets/orthoptic";

interface IKrimskyTest {
    orthopticPayload: OrthopticPayload;
    setOrthopticPayload: (bielschowskyPayload: OrthopticPayload) => void;
    onSubmit: () => void;
    readOnly?: boolean;
    isDisabledForm: boolean;
}

export default function KrimskyTest({ orthopticPayload, setOrthopticPayload, onSubmit, readOnly, isDisabledForm }: IKrimskyTest) {

    const onChangeCorrection = (corrected: boolean | null) => {
        const options: OrthopticPayload = {
            ...orthopticPayload,
            krimskyTest: {
                ...orthopticPayload.krimskyTest,
                correction: corrected
            }
        };
        setOrthopticPayload(options);
        onSubmit();
    };

    const onChangeSightTest = (result: { vl?: SightConfig; vp?: SightConfig; }) => {
        const options: OrthopticPayload = {
            ...orthopticPayload,
            krimskyTest: {
                ...orthopticPayload.krimskyTest,
                ...result
            }
        };
        setOrthopticPayload(options);
        onSubmit();
    };

    const render = () => {
        return (
            <SectionCard>
                <div className="d-flex align-items-center">
                    <h5 className="flex-grow-1 text-secondary fw-bold">
                        Test de Krimsky
                    </h5>
                    <div className="d-flex align-items-center">
                        <RadioButton
                            disabled={isDisabledForm}
                            className="me-5 fs-5"
                            label="Sin corrección"
                            name="correctionKT"
                            id="withNoCorrectionKT"
                            onClick={() => {
                                if (orthopticPayload.krimskyTest?.correction === false) {
                                    onChangeCorrection(null);
                                } else {
                                    onChangeCorrection(false);
                                }
                            }}
                            checked={(orthopticPayload?.krimskyTest?.correction === false || !orthopticPayload?.krimskyTest?.correction)}
                            readOnly={readOnly}
                        />
                        <RadioButton
                            disabled={isDisabledForm}
                            className="fs-5"
                            label="Con corrección"
                            name="correctionKT"
                            id="withCorrectionKT"
                            onClick={() => {
                                if (orthopticPayload.krimskyTest?.correction === true) {
                                    onChangeCorrection(null);
                                } else {
                                    onChangeCorrection(true);
                                }
                            }}
                            checked={(orthopticPayload?.krimskyTest?.correction === true || !!orthopticPayload?.krimskyTest?.correction)}
                            readOnly={readOnly}
                        />
                    </div>
                </div>
                <hr className="text-primary" />
                <div className="row">
                    <div className="col-lg-6">
                        <SightTest
                            isDisabledForm={isDisabledForm}
                            type="vl"
                            onGetResult={(result) => onChangeSightTest(result)}
                            currentData={orthopticPayload.krimskyTest?.vl}
                            isPrism
                            readOnly={readOnly}
                        />
                    </div>
                    <div className="col-lg-6">
                        <SightTest
                            isDisabledForm={isDisabledForm}
                            type="vp"
                            onGetResult={(result) => onChangeSightTest(result)}
                            currentData={orthopticPayload.krimskyTest?.vp}
                            isPrism
                            readOnly={readOnly}
                        />
                    </div>
                </div>
            </SectionCard>
        );
    };

    return render();
}
import { Badge, Button, Dropdown, DropdownItem, DropdownMenu, Modal } from "@/components";
import { IModal } from "@/components/Modal/modal";
import { finishLoading, startLoading } from "@/config/slices";
import DownloadHistoryFile from "@/features/Workspace/GeneralHistory/DownloadHistoryFile";
import { getDownloadHistory } from "@/features/Workspace/GeneralHistory/generalHistory.actions";
import { getHeaderSurgery } from "@/features/Workspace/PatientAttention/Surgery/patientAttentionSurgery.action";
import { useAppDispatch } from "@/hooks";
import { DilatationRes, IVitalSigns, TData } from "@/models";
import { Segments } from "@/models/generalFields";
import { DownloadHistory } from "@/models/generalHistory/generalHistory";
import { DiagnosticImpression } from "@/models/historyDetails/diagnosticImpression";
import { ReconciliationMedicine } from "@/models/pre-consultation/medicine";
import { DIListItem } from "@/models/select/diagnosticImpression";
import { AdaptationPayload } from "@/models/sheets/adaptation";
import { LowVisionPayload } from "@/models/sheets/lowVision";
import { OftalmologySheet } from "@/models/sheets/oftalmology";
import { OptometryPayload } from "@/models/sheets/optometry";
import { CommonSelect, OrthopticPayload, Squints } from "@/models/sheets/orthoptic";
import { PreoperativePayload } from "@/models/sheets/preoperative";
import { HeaderSurgery } from "@/models/surgery/patientAttention/headerSurgeryAttention";
import { SurgeryStage } from "@/models/surgery/surgeryStages";
import { IconChevronUp, IconPrinter } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Adaptation from "../../../Adaptation/Adaptation";
import LowVision from "../../../LowVision/LowVision";
import Oftalmology from "../../../Oftalmology/Oftalmology";
import Optometry from "../../../Optometry/Optometry";
import Orthoptic from "../../../Orthoptic/Orthoptic";
import PreConsultation from "../../../PreConsultation/PreConsultation";
import Preoperative from "../../../Preoperative/Preoperative";
import { getAHAdaptationDetail, getAHDIDetails, getAHLowVisionDetail, getAHOphthalmologyDetail, getAHOptometryDetail, getAHOrthopticDetail, getAHPreconsultingDetail, getAHPreoperativeDetail } from "../../history.actions";
import DiagnosticImpressionDetails from "../Details/DiagnosticImpressionDetails";
import BackgroundDetail from "../Details/SurgeryDetails/BackgroundDetail/BackgroundDetail";
import OrdersDetail from "../Details/SurgeryDetails/OrdersDetail/OrdersDetail";
import SurgeryDetails from "../Details/SurgeryDetails/SurgeryDetails";
import SurgeryHeader from "../Details/SurgeryDetails/SurgeryHeader";
import UnexpectedSituationDetail from "../Details/SurgeryDetails/UnexpectedSituationDetail/UnexpectedSituationDetail";
import { getSurgeryStages } from "../Details/SurgeryDetails/surgeryDetails.actions";

interface DetailParams {
    sheet?: string;
    mcfId?: number;
    diId?: number;
    origin?: string;
    allData?: boolean;
    appId?: number;
}
interface IDetails extends IModal<{ appId?: number; }> {
    appId: number;
    sheet: { name: string; prefix: string; origin?: string; };
    additionalData?: DetailParams;
    dataVersionList?: CommonSelect[]
    squintsData?: Squints[];
    degreesData?: CommonSelect[]

}

const HistoryComponent = ({ appId:appIdState, sheet, additionalData, dataVersionList, squintsData, degreesData, ...rest }: IDetails) => {

    const dispatch = useAppDispatch();
    const printFile = useRef<HTMLTableElement>(null);
    const printAction = useReactToPrint({
        content: () => printFile.current
    });
    const [surgeryHeader, setSurgeryHeader] = useState<HeaderSurgery>();
    const [stageOptions, setStageOptions] = useState<SurgeryStage[]>([]);
    const [selectedOption, setSelectedOption] = useState<SurgeryStage>({
        name: "",
        prefix: ""
    });
    const [appId, setAppId] = useState(appIdState);

    const [diDetail, setDiDetail] = useState<DiagnosticImpression>({});

    const [lowVisionDetails, setLowVisionDetails] = useState<{
        dataSheet: LowVisionPayload, diagnosticImpression: DIListItem[]; generalsFields: Segments[];
    }>();
    const [optometryDetails, setOptometryDetails] = useState<{
        dataSheet: OptometryPayload, diagnosticImpression: DIListItem[]; generalsFields: Segments[];
    }>();

    const [ophthalmologyDetails, setOphthalmologyDetails] = useState<{
        dataSheet: OftalmologySheet, diagnosticImpression: DIListItem[]; generalsFields: Segments[];
    }>();
    const [adaptationDetail, setAdaptationDetail] = useState<{
        dataSheet: AdaptationPayload, diagnosticImpression: DIListItem[]; generalsFields: Segments[];
    }>();
    const [orthopticDetails, setOrthopticDetails] = useState<{
        dataSheet: OrthopticPayload, diagnosticImpression: DIListItem[]; generalsFields: Segments[];
    }>();
    const [preconsultingDetail, setPreconsultingDetail] = useState<{
        dataSheet: {
            dilatation: DilatationRes;
            medicationReconciliation: { currentUse: ReconciliationMedicine[], suspended: ReconciliationMedicine[]; };
            vitalSings: IVitalSigns;
            ocularSifting: TData;
            orthoptic: OrthopticPayload
        },
        generalsFields: Segments[];
        diagnosticImpression: DIListItem[];
    }
    >();

    const [preoperativeDetails, setPreoperativeDetails] = useState<{
        dataSheet: PreoperativePayload, diagnosticImpression: DIListItem[]; generalsFields: Segments[];
    }>();

    const [downloadData, setDownloadData] = useState<DownloadHistory[] | null>();
    const [downloadDataOld, setDownloadDataOld] = useState<DownloadHistory[] | null>();
    const [trigger, setTrigger] = useState(0);


    const handlePrint = async () => {
        const response = await dispatch(getDownloadHistory({
            appId,
        }));

        setDownloadDataOld(downloadData);
        if (response) {
            setDownloadData(response);
            setTrigger(trigger + 1);
        }
    };


    useEffect(() => {
        (async function () {
            dispatch(startLoading());
            await new Promise(resolve => setTimeout(resolve, 5000));
            if (downloadData !== downloadDataOld) {
                printAction(); 
            }
            dispatch(finishLoading());
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger, downloadData]);


    useEffect(() => {
        setSelectedOption({
            name: sheet.name,
            prefix: sheet.prefix,
        });
    }, [sheet]);

    useEffect(() => {
        void (async function () {
            if (appId) {
                const response = await dispatch(getSurgeryStages({ appId: appId }));
                setStageOptions(response);
            }
        })();
    }, [appId, dispatch]);

    useEffect(() => {
        void (async function () {
            const response = await dispatch(getHeaderSurgery(appId));
            if (response) setSurgeryHeader(response);
        })();
    }, [appId, dispatch]);


    useEffect(() => {
        async function fetch() {
            if (sheet?.prefix === "ophthalmology") {
                const response = await dispatch(getAHOphthalmologyDetail({
                    prefix: sheet?.prefix,
                    appId
                }));
                if (response) {
                    setOphthalmologyDetails({
                        dataSheet: response.ophthalmology,
                        diagnosticImpression: response.diagnosticImpression,
                        generalsFields: response.generalsFields
                    });
                }
            }
        }
        fetch();

    }, [sheet?.prefix, dispatch,appId]);

    useEffect(() => {
        async function fetch() {
            if (sheet?.prefix === "optometry") {
                const response = await dispatch(getAHOptometryDetail({
                    prefix: sheet?.prefix,
                    appId,
                }));
                if (response) setOptometryDetails({
                    dataSheet: response.optometry,
                    diagnosticImpression: response.diagnosticImpression,
                    generalsFields: response.generalsFields,
                });
            }
        }
        fetch();
    }, [sheet?.prefix, dispatch,appId]);

    useEffect(() => {
        async function fetch() {
            if (sheet?.prefix === "lowVision") {
                const response = await dispatch(getAHLowVisionDetail({
                    prefix: sheet?.prefix,
                    appId: appId
                }));
                if (response) {
                    setLowVisionDetails({
                        dataSheet: response.lowVision,
                        diagnosticImpression: response.diagnosticImpression,
                        generalsFields: response.generalsFields,
                    });
                }
            }
        }
        fetch();
    }, [appId, sheet?.prefix, dispatch]);

    useEffect(() => {
        async function fetch() {
            if (sheet?.prefix === "orthoptic") {
                const response = await dispatch(getAHOrthopticDetail({
                    prefix: sheet?.prefix,
                    appId,
                }));
                if (response) {
                    setOrthopticDetails({
                        dataSheet: response.orthoptic,
                        diagnosticImpression: response.diagnosticImpression,
                        generalsFields: response.generalsFields,
                    });
                }
            }
        }
        fetch();

    }, [appId, sheet?.prefix, dispatch]);
    useEffect(() => {
        async function fetch() {
            if (sheet?.prefix === "preconsulting" || selectedOption.prefix === "preconsulting") {
                const response = await dispatch(getAHPreconsultingDetail({
                    prefix: sheet?.origin ? sheet?.origin : sheet.prefix,
                    appId,
                }));
                if (response) setPreconsultingDetail({
                    dataSheet: {
                        dilatation: response.preconsultation?.dilation.dilations,
                        ocularSifting: response.preconsultation.ocularSifting,
                        medicationReconciliation: response.preconsultation.medicationReconciliation,
                        vitalSings: response.preconsultation.vitalSigns,
                        orthoptic: response.preconsultation.orthoptic
                    },
                    diagnosticImpression: response.diagnosticImpression,
                    generalsFields: response.generalsFields,

                });
            }
        }
        fetch();

    }, [sheet?.prefix, dispatch, appId, selectedOption.prefix]);


    useEffect(() => {
        async function fetch() {
            if (sheet?.prefix === "adaptation") {
                const response = await dispatch(getAHAdaptationDetail({
                    prefix: sheet?.prefix,
                    appId,
                }));
                if (response) setAdaptationDetail({
                    dataSheet: response.adaptation,
                    diagnosticImpression: response.diagnosticImpression,
                    generalsFields: response.generalsFields,
                });
            }
        }
        fetch();

    }, [sheet?.prefix, dispatch, appId]);


    useEffect(() => {
        async function fetch() {
            if (sheet?.prefix === "preoperative") {
                const response = await dispatch(getAHPreoperativeDetail({
                    prefix: sheet?.prefix,
                    appId,
                }));
                if (response) {
                    setPreoperativeDetails({
                        dataSheet: response.preoperative,
                        diagnosticImpression: response.diagnosticImpression,
                        generalsFields: response.generalsFields
                    });
                }
            }
        }
        fetch();
    }, [sheet?.prefix, dispatch,appId]);



    useEffect(() => {
        async function fetch() {
            if (sheet.prefix === "diagnosticImpression") {
                const response = await dispatch(getAHDIDetails({
                    prefix: sheet.prefix,
                    mcfId: additionalData && additionalData.mcfId
                }));
                if (response) {
                    const diSelected = response.diagnosticImpression?.find(
                        el => el.id === additionalData ? additionalData?.diId : NaN
                    );
                    if (diSelected) setDiDetail(diSelected);
                }
            }
        }
        fetch();

    }, [sheet.prefix, appId, dispatch,]);




    const render = () => {
        return (
            <>
                {surgeryHeader && (
                    <SurgeryHeader patientHeader={surgeryHeader} title={sheet.prefix === "adaptation" ? "Adaptación LC" : sheet.name} setAppIdState={setAppId} prefix={sheet?.prefix}/>
                )}
                <div className="overflow-auto d-flex h-100">
                    {selectedOption.prefix === "unexpectedSituation" &&
                        <UnexpectedSituationDetail appId={appId || NaN} />
                    }
                    {(selectedOption.prefix === "ophthalmology") &&
                        <Oftalmology data={ophthalmologyDetails} historySheet appId={appId} />
                    }
                    {(selectedOption.prefix === "optometry") &&
                        <Optometry data={optometryDetails} historySheet appId={appId} />
                    }
                    {(selectedOption.prefix === "lowVision") &&
                        <LowVision data={lowVisionDetails} historySheet appId={appId} />
                    }
                    {(selectedOption.prefix === "orthoptic") &&
                        <Orthoptic data={orthopticDetails} historySheet appId={appId} />
                    }
                    {(selectedOption.prefix === "preconsulting") &&
                        <PreConsultation data={preconsultingDetail} historySheet appId={appId} />}
                    {(selectedOption.prefix === "preoperative") &&
                        < Preoperative data={preoperativeDetails} historySheet appId={appId} />
                    }
                    {(selectedOption.prefix === "adaptation") &&
                        <Adaptation data={adaptationDetail} historySheet appId={appId} />
                    }
                    {(selectedOption.prefix === "background") &&
                        <BackgroundDetail appId={appId || NaN} />}
                    {selectedOption.prefix === "diagnosticImpression" &&
                        <DiagnosticImpressionDetails forDownload diDetail={diDetail} />}
                    {selectedOption.prefix === "orders" && <OrdersDetail appId={appId} />}

                </div >



                <div className="surgery-footer">
                    <Dropdown className="dropdown">
                        <Button
                            endIcon={<IconChevronUp className="text-primary" />}
                            color="secondary"
                            variant="outline"
                            className="px-2"
                            data-bs-toggle="dropdown"
                            style={{ width: 165 }}
                        >
                            {selectedOption.prefix === "adaptation" ? "Adaptación LC" : selectedOption.name}
                        </Button>
                        <DropdownMenu className="w-100">
                            {stageOptions.map(opt => (
                                <DropdownItem
                                    key={opt.prefix}
                                    onClick={() => setSelectedOption({ name: opt.name, prefix: opt.prefix })}
                                >
                                    {opt.prefix === "adaptation" ? "Adaptación LC" : opt.name}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                    <div className="flex-grow-1"></div>
                    {surgeryHeader && (
                        <Badge className="ms-4" style={{
                            backgroundColor: surgeryHeader.status.bgColor || "#E8E8EA",
                            color: surgeryHeader?.status?.fontColor || "#6E6F7C"
                        }}>
                            {surgeryHeader.status.name}
                        </Badge>
                    )}

                    <div className="col-1 align-self-end">
                        <div className="pointer text-center"
                            onClick={() => handlePrint()}
                        >
                            <IconPrinter
                                className="text-muted pointer ms-3"
                            />
                        </div>
                    </div>


                </div>
                {downloadData &&
                    <div style={{ overflow: "hidden", height: 0 }} key={`${downloadData} ${trigger}`}
                    > 
                        <DownloadHistoryFile
                            key={`${downloadData} ${trigger}`}
                            ref={printFile}
                            data={downloadData}
                            fromHistory
                            dataVersionList={dataVersionList}
                            degreesData={degreesData}
                            squintsData={squintsData}
                        />
                    </div>
                }
            </>
        );
    };


    return (
        <Modal width={1400} fullScreen {...rest} className="p-0">
            {
                sheet.prefix === "surgery" ?
                    <SurgeryDetails appId={appId} />
                    : render()

            }

        </Modal>

    );
};

export default HistoryComponent;
import { useAppDispatch, useAppSelector } from "@/hooks";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
//
import { AttentionOriginFields } from "@/models/attentionOrigin";
import { OrthopticPayload } from "@/models/sheets/orthoptic";
import { getOrthoptic, saveOrthoptic } from "./orthoptic.action";
//
import AttentionOrigin from "../AttentionOrigin/AttentionOrigin";
import ConsultationControls from "../Controls/Controls";
import ConsultationHeader from "../Header/Header";
import History from "../History/History";
// import VisualAcuity from "../shared/VisualAcuity/VisualAcuity";
import BielschowskyTest from "./BielschowskyTest/BielschowskyTest";
import ChromaticVision from "./ChromaticVision/ChromaticVision";
import FinalFormula from "./FinalFormula/FinalFormula";
import Laterality from "./Laterality/Laterality";
//
import { Segments } from "@/models/generalFields";
import { DIListItem } from "@/models/select/diagnosticImpression";
import AnalysisAndPlan from "../AttentionOrigin/AnalysisAndPlan";
import "../Consultation.scss";
import DiagnosticImpression from "../DiagnosticImpression/DiagnosticImpression";
import Accomodation from "./Accomodation/Accomodation";
import Fixation from "./Fixation/Fixation";
import Flexibility from "./Flexibility/Flexibility";
import FusionalBreadths from "./FusionalBreadths/FusionalBreadths";
import HirschbergReflex from "./HirschbergReflex/HirschbergReflex";
import KappaAngle from "./KappaAngle/KappaAngle";
import KrimskyTest from "./KrimskyTest/KrimskyTest";
import NextConvergencePoint from "./NextConvergencePoint/NextConvergencePoint";
import PrismCoverTest from "./PrismCoverTest/PrismCoverTest";
import Sensory from "./Sensory/Sensory";
import Stereopsis from "./Stereopsis/Stereopsis";
import Versions from "./Versions/Versions";
import WhiteTest from "./WhiteTest/WhiteTest";

export default function Orthoptic({ historySheet, data, appId, showRequiredFields = true }: {
    historySheet?: boolean;
    data?: {
        dataSheet?: OrthopticPayload, generalsFields: Segments[]; diagnosticImpression: DIListItem[];
    };
    appId?: number;
    showRequiredFields?: boolean;
}) {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { dataSheet, diagnosticImpression, generalsFields } = data || {};

    const currentSheet = useAppSelector(state => state.patientAttention.patientStatus.sheet);
    const appointmentId = useAppSelector(state => state.patientAttention.appointmentSelected.app_id);
    const accountId = useAppSelector(state => state.workspace.id);
    const userId = useAppSelector(state => state.auth.user_data.id);
    const mcfId = useAppSelector(state => state.patientAttention.patientStatus.mcfId);
    const isDisabledForm = historySheet ? historySheet : false;
    const [attentionOriginOptions, setAttentionOriginOptions] = useState<AttentionOriginFields>({
        appId: appointmentId,
        eaccount: accountId,
        userId: userId as number,
        generalFields: {
            attentionOriginId: undefined,
            abserveEvent: undefined,
            evolutionTime: undefined,
            evolutionTimeUnitId: undefined
        },
        reasonForConsulting: { predefinedTxts: [], justification: "" },
        currentDisease: { predefinedTxts: [], justification: "" },
        analysisPlan: ""
    });
    const [orthopticPayload, setOrthopticPayload] = useState<OrthopticPayload>({
        appId: appointmentId,
        eaccount: accountId,
        userId: userId as number
    });

    const splittedPath = location.pathname.split("/");
    const sheet = splittedPath[splittedPath.length - 1];

    useEffect(() => {
        if (!historySheet && !(sheet === "preconsulting" || sheet === "generalHistory")) {

            if (sheet !== currentSheet) {
                navigate(-1);
            }
        }
    }, [currentSheet, navigate, location.pathname, historySheet, sheet]);

    useEffect(() => {
        async function fetchData() {
            if (dataSheet) {
                setOrthopticPayload({
                    ...dataSheet,
                    eaccount: accountId,
                    userId: userId as number,
                    appId: appointmentId,
                });
            } else {
                const data = await dispatch(getOrthoptic({
                    appId: appointmentId,
                    eaccount: accountId,
                    userId: userId as number
                }));
                if (data) {
                    setOrthopticPayload({
                        ...data,
                        eaccount: accountId,
                        userId: userId as number,
                        appId: appointmentId,
                    });
                }

            }
        }
        fetchData();
    }, [dispatch, appointmentId, accountId, mcfId, userId, dataSheet, historySheet]);

    const onSubmitOrthoptic = useDebouncedCallback(() => {
        if (orthopticPayload) {
            const options = { ...orthopticPayload };

            setOrthopticPayload(options);
            dispatch(saveOrthoptic(options));
        }
    }, 1000);

    const render = () => {
        return (
            <div className="d-flex flex-column h-100 overflow-hidden">
                {showRequiredFields && !historySheet &&
                    <ConsultationHeader isDisabledForm={isDisabledForm} currentSection="Ortóptica" showHistory={false} />
                }
                <div className="d-flex overflow-hidden h-100">
                    <section className={`consultation-section ${!showRequiredFields && "ps-0 pe-2"}`}>
                        <div className="row g-3">
                            {showRequiredFields && (
                                <div className="col-12">
                                    <AttentionOrigin
                                        historySheet={historySheet}
                                        data={generalsFields}
                                        isDisabledForm={isDisabledForm}
                                        attentionOriginOptions={attentionOriginOptions}
                                        setAttentionOriginOptions={setAttentionOriginOptions}
                                    />
                                </div>
                            )}
                            <div className="col-5">
                                <FinalFormula
                                    isDisabledForm={isDisabledForm}
                                    historySheet={historySheet}
                                    orthopticPayload={orthopticPayload}
                                    setOrthopticPayload={setOrthopticPayload}
                                    onSubmit={() => onSubmitOrthoptic()}
                                />
                            </div>
                            <div className="col-5">
                                <FusionalBreadths
                                    isDisabledForm={isDisabledForm}
                                    orthopticPayload={orthopticPayload}
                                    setOrthopticPayload={setOrthopticPayload}
                                    onSubmit={() => onSubmitOrthoptic()}
                                />
                            </div>
                            <div className="col-lg-2">
                                <Fixation
                                    historySheet={historySheet}
                                    isDisabledForm={isDisabledForm}
                                    orthopticPayload={orthopticPayload}
                                    setOrthopticPayload={setOrthopticPayload}
                                    onSubmit={() => onSubmitOrthoptic()}
                                />
                            </div>
                            <div className="col-lg-6">
                                <HirschbergReflex
                                    isDisabledForm={isDisabledForm}
                                    orthopticPayload={orthopticPayload}
                                    setOrthopticPayload={setOrthopticPayload}
                                    onSubmit={() => onSubmitOrthoptic()}
                                />
                            </div>
                            <div className="col-lg-6">
                                <KrimskyTest
                                    isDisabledForm={isDisabledForm}
                                    orthopticPayload={orthopticPayload}
                                    setOrthopticPayload={setOrthopticPayload}
                                    onSubmit={() => onSubmitOrthoptic()}
                                />
                            </div>
                            <div className="col-lg-6">
                                <PrismCoverTest
                                    isDisabledForm={isDisabledForm}
                                    orthopticPayload={orthopticPayload}
                                    setOrthopticPayload={setOrthopticPayload}
                                    onSubmit={() => onSubmitOrthoptic()}
                                />
                            </div>
                            <div className="col-lg-6">
                                <WhiteTest
                                    isDisabledForm={isDisabledForm}
                                    orthopticPayload={orthopticPayload}
                                    setOrthopticPayload={setOrthopticPayload}
                                    onSubmit={() => onSubmitOrthoptic()}
                                />
                            </div>
                            <div className="col-lg-6">
                                <Versions
                                    isDisabledForm={isDisabledForm}
                                    orthopticPayload={orthopticPayload}
                                    setOrthopticPayload={setOrthopticPayload}
                                    onSubmit={() => onSubmitOrthoptic()}
                                    showHistory
                                // showHistory={showHistory}
                                />
                            </div>
                            <div className="col-6">
                                <div className=" ">
                                    <BielschowskyTest
                                        isDisabledForm={isDisabledForm}
                                        orthopticPayload={orthopticPayload}
                                        setBielschowskyPayload={setOrthopticPayload}
                                        onSubmit={() => onSubmitOrthoptic()}
                                    />
                                </div>
                                <div className="d-flex  mt-3">
                                    <div className="col-6 pr-1" style={{ paddingRight: 5 }}>
                                        <NextConvergencePoint
                                            isDisabledForm={isDisabledForm}
                                            orthopticPayload={orthopticPayload}
                                            setOrthopticPayload={setOrthopticPayload}
                                            onSubmit={() => onSubmitOrthoptic()}
                                        />
                                    </div>
                                    <div className="col-6 pl-1" style={{ paddingLeft: 5 }}>
                                        <Flexibility
                                            isDisabledForm={isDisabledForm}
                                            historySheet={historySheet}
                                            orthopticPayload={orthopticPayload}
                                            setOrthopticPayload={setOrthopticPayload}
                                            onSubmit={() => onSubmitOrthoptic()}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <Laterality
                                            historySheet={historySheet}
                                            isDisabledForm={isDisabledForm}
                                            orthopticPayload={orthopticPayload}
                                            setOrthopticPayload={setOrthopticPayload}
                                            onSubmit={() => onSubmitOrthoptic()}
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <KappaAngle
                                            historySheet={historySheet}
                                            isDisabledForm={isDisabledForm}
                                            orthopticPayload={orthopticPayload}
                                            setOrthopticPayload={setOrthopticPayload}
                                            onSubmit={() => onSubmitOrthoptic()}
                                        />
                                    </div>

                                </div>
                            </div>





                            <div className="col-6">
                                <Accomodation
                                    historySheet={historySheet}
                                    isDisabledForm={isDisabledForm}
                                    orthopticPayload={orthopticPayload}
                                    setOrthopticPayload={setOrthopticPayload}
                                    onSubmit={() => onSubmitOrthoptic()}
                                />
                            </div>
                            <div className="col-4">
                                <ChromaticVision
                                    isDisabledForm={isDisabledForm}
                                    orthopticPayload={orthopticPayload}
                                    setOrthopticPayload={setOrthopticPayload}
                                    onSubmit={onSubmitOrthoptic}
                                />
                            </div>

                            <div className="col-5">
                                <Sensory
                                    historySheet={historySheet}
                                    isDisabledForm={isDisabledForm}
                                    orthopticPayload={orthopticPayload}
                                    setOrthopticPayload={setOrthopticPayload}
                                    onSubmit={() => onSubmitOrthoptic()}
                                />
                            </div>


                            <div className="col-3">
                                <Stereopsis
                                    historySheet={historySheet}

                                    isDisabledForm={isDisabledForm}
                                    orthopticPayload={orthopticPayload}
                                    setOrthopticPayload={setOrthopticPayload}
                                    onSubmit={() => onSubmitOrthoptic()}
                                />
                            </div>
                            {showRequiredFields && (
                                <div className="col-lg-6">
                                    <DiagnosticImpression isDisabledForm={isDisabledForm}
                                        historySheet={historySheet}
                                        data={{
                                            appId,
                                            cluId: undefined,
                                            userId: NaN,
                                            eaccount: accountId,
                                            clhId: undefined,
                                            sheet: "orthoptic",
                                            information: diagnosticImpression
                                        }
                                        } />
                                </div>
                            )}
                            {showRequiredFields && (
                                <div className="col-lg-6">
                                    <AnalysisAndPlan
                                        isDisabledForm={isDisabledForm}
                                        attentionOriginOptions={attentionOriginOptions}
                                        setAttentionOriginOptions={setAttentionOriginOptions}
                                        height={220}
                                        data={generalsFields && generalsFields[0].analysisPlanJst}

                                    />
                                </div>

                            )}
                        </div>
                    </section>
                    {!historySheet && showRequiredFields && <History showClose={false} />}
                </div>
                {(!historySheet && showRequiredFields) &&
                    <ConsultationControls isDisabledForm={isDisabledForm} />
                }
            </div>
        );
    };


    return render();
}